.collection-view-container .col1 {
    width: 20%;
}

.collection-view-container .col2 {
    width: 10%;
}

.collection-view-container .col3 {
    width: 10%;
}

.collection-view-container .col4 {
    width: 25%;
}

.collection-view-container .col5 {
    width: 25%;
}

.collection-view-container .col6 {
    width: 10%;
}