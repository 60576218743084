/* Benchmark Project View Related  Table Css */
.project-info-table-cell-text {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    color: black;
    width: 50%;
    text-align: left;
    padding: 7px
}

.project-info-table-cell-right-aligned-number {
    border: 1px solid black;
    color: black;
    width: 100px;
    text-align: right
}

.project-info-table-cell-left-aligned-number {
    border: 1px solid black;
    color: black;
    width: 100px;
    text-align: left
}

.project-info-table-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.project-info-table-sub-header-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background: linear-gradient(135deg, rgb(50, 98, 149) 0%, rgb(101, 137, 176) 100%);
    height: 35px;
    color: white;
    font-size: 20px;
    padding-left: 10px;
}


.info-table-container{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 10px;
    margin-top:10px;
}
.info-table{
    height:650px;
    overflow-y: auto;
    scrollbar-width: none;
}

.info-table-row {
    display: flex;
    flex-direction: row;   
    border-left:1px solid rgba(0, 0, 0, 0.09); 
}

.info-table-cell {
    color: black;
    font-size: 17px;
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    vertical-align: middle;
    padding: 5px;
    word-wrap: break-word;
}


.description {
    text-align: left;
    width: 100%;
}

.info-table-cell.text,
.info-table-cell.info-table-cell.header.text,
.info-table-cell.info-table-cell.total-subheading.text
{
    text-align: center;
    width: 300px;
}
.number,.currency {
    text-align: right;
    width:300px;
}
.currency{
    width:350px;
}
.header{
    background-color:var(--fluidity1) ;
    color:white;
    text-align:center;
}
.total-subheading{
    background-color: var(--fluidity3);
    color:black;    
    text-align:right;
}

.subtotal-subheading{
    background-color:var(--fluidity2);
    color:white;    
    text-align:right;
    font-weight:500;
}

.grandtotal-subheading{
    background-color:var(--fluidity1);
    color:white;    
    text-align:right;
    font-weight: 500;
}

.info-table::-webkit-scrollbar {
    width: 10px;
  }