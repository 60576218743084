.articles-display {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: flex-start;
  width: 1650px;
}

/* Article Image */
.articles-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--integrity);
  color: var(--confident);
  width: 320px;
  height: 300px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}

/* Insights Image */
.articles-card-image {
  height: 300px;
  width: 450px;
}

.articles-card:hover {
  -webkit-transform: scale(1.085);
  -ms-transform: scale(1.085);
  transform: scale(1.085);
  cursor: pointer;
}

.articles-card-link {
  margin: 30px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
}

.articles-card-link:hover {
  text-decoration: none;
}

.articles-card-link:hover .articles-card-title-row {
  background-color: rgba(0, 0, 0, 0.7);
  transition: 1s ease all;
}

.articles-card-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: rgba(0, 0, 0, 0.3);
  color: var(--integrity);
  width: 100%;
  padding: 30px;
  margin-top: -118px;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.article-card-author-image {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.articles-card-button-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 50px;
  margin-top: 30px;
  position: absolute;
  /* z-index: 1; */
}

.articles-card-link-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.articles-card-unread-article-notification {
  margin-top: -5px;
  margin-left: 312px;
}

.articles-card-unread-category-notification {
  margin-top: -5px;
  margin-left: 448px;
}
