.pcf-element {
  display: flex;
  flex-direction: row;
  align-items: left;
}

.pcf-element-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  height: 30px;
  margin-left: 5px;
}

.pcf-validation-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  height: 20px;
  font-size: small;
  color: red;
}

.pcf-container {
  min-width: 250px;
  margin: 10px;
}

.pcf-container .checkbox-label {
  width: 400px;
}

.pcf-date-item {
  align-items: center;
  width: 200px;
  height: 30px;
}

input[type=date]:invalid::-webkit-datetime-edit {
  color: #999;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1
}

.pcf-element-item input[type="button"] {
  width: 200px;
  border: gray;
}

.bm-table-props {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  padding-top: 10px;
}

.bm-container .bm-table-label {
  word-wrap: true;
  width: 200px;
}

/* Benchmark Notes */
.bm-notes-container .general-row-container {
  display: block;
}

.bm-notes-container textarea {
  width: 100%;
  height:35px;
}

.bm-notes-container .general-row-container:has(.general-upload-button),
.bm-notes-container .general-row-container:has(.pcu-error-container) {
  display: flex;
}
.bm-notes-container .general-row-container > input{
  height:35px;
}
.bm-notes-container .general-row-container:focus-within{
  font-weight: bold;
}

/* ELEMENTS css */
.bm-container .bm-table-element {
  width: 135px;
}
.bm-container .general-row-container input:nth-child(3),
.bm-container .general-row-container input:nth-child(4),
.bm-container .general-row-container input:nth-child(5),
.bm-container .general-row-container input:nth-child(6),
.bm-container .general-row-container input:nth-child(8)
{
  text-align: right;
}
.bm-container .general-row-container:focus-within{
  font-weight: bold;
}

.bm-container .pcf-element-item {
  width: 135px;
}
.bm-container .bm-table-props .general-row-container .pcf-element-item:first-child{
  width: 60px;
}
.bm-container .bm-table-props .general-row-container .pcf-element-item:nth-child(2){
  width: 200px;
  height:auto;
}
.bm-container  .general-row-container .pcf-element-item:first-child{
  width: 60px;
}
.bm-container  .general-row-container .pcf-element-item:nth-child(2){
  width: 200px;
}
.bm-container .general-row-container input:focus{
  outline: auto;  
}
.bm-container .bm-upload-button-container .general-row-container .pcf-element div:first-child{
  width: 200px !important;
}

/*METRICS CSS*/
.bm-metrics-container .general-row-container  div:first-child{
  width:50px;
}
.bm-metrics-container .general-row-container  div:nth-child(2){
  width:400px;
}
.bm-metrics-container .general-row-container input:nth-child(3) {
  text-align: right;
 }
.bm-metrics-container .general-row-container input:focus{
  outline: auto;  
}
.bm-metrics-container .general-row-container:focus-within{
  font-weight: bold;
}

.bm-metrics-container .general-row-container .feedback-success{
width:100% !important;

}
/*PROJECT INFO CSS*/
.bm-pinfo-container .general-row-container  div:first-child{
  width:50px;
}
.bm-pinfo-container .general-row-container  div:nth-child(2){
  width:400px;
}
.bm-pinfo-container .general-row-container input:nth-child(3) {
  text-align: left;
  width:300px;
 }
 .bm-pinfo-container .general-row-container div:nth-child(3) .pcf-element-item {
  text-align: left;
  width:300px;
 }

.bm-pinfo-container .general-row-container input:focus{
  outline: auto;    
}
.bm-pinfo-container .general-row-container:focus-within{
  font-weight: bold;
}

.bm-pinfo-container .general-row-container .feedback-success{
width:100% !important;

}
.bm-pinfo-container .general-row-container div:nth-child(3) {
  text-align: left;
  width:300px;
 }

 .pcf-table-date-item{
  align-items: center;
  width: 300px;
  height: 30px;
}
