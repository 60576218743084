.post-contract-selector-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Selector Loading */

.post-contract-selector-loading {
  width: 365px;
}

/* Selector */

.post-contract-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.post-contract-register-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

/* Slider Label */

.post-contract-stages-label {
  display: flex;
  justify-content: center;
  width: 300px;
}

/* Slider */

.post-contract-stages-slider {
  appearance: none;
  width: 300px;
  height: 13px;
  background: var(--collaboration1);
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.post-contract-stages-slider:hover {
  opacity: 1;
}

.post-contract-stages-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--heritage);
  cursor: pointer;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.post-contract-stages-slider::-moz-range-thumb {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 25px;
  height: 25px;
  background: var(--heritage);
  cursor: pointer;
  border-radius: 100px;
}

.post-contract-stages-slider.pcra-in-progress::-webkit-slider-thumb {
  background: var(--collaboration4);
  border: 5px solid var(--heritage);
}

.post-contract-stages-slider.pcra-in-progress::-moz-range-thumb {
  background: var(--collaboration4);
  border: 5px solid var(--heritage);
}

.post-contract-datalist {
  margin-top: 10px;
}

/* Package Tabs */

.post-contract-package-selector {
  margin-right: 30px;
}

.post-contract-package-selector-tab {
  background-color: var(--collaboration1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: var(--integrity);
  margin: 3px;
  padding-left: 3px;
  padding-right: 3px;
}

.post-contract-package-selector-tab:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.post-contract-package-selector-tab-selected {
  background-color: var(--collaboration3);
  color: var(--confident);
  margin: 3px;
  padding-left: 3px;
  padding-right: 3px;
}


  /* On mouse-over, add a grey background color */
  .package-option:hover input ~ .option-checkbox {
    background-color: var(--collaboration1);
  }
  
  /* When the checkbox is checked, add a blue background */
  .package-option input:checked ~ .option-checkbox {
    background-color: var(--heritage);
  }
  
  /* When the checkbox is checked, add a blue background */
  .package-option:hover input:checked ~ .option-checkbox {
    background-color: rgb(202, 82, 27);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .option-checkbox:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .package-option input:checked ~ .option-checkbox:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .package-option .option-checkbox:after {
    left: 8px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .package-option{
    width: 200px;
    text-align: left;
    display: block;
    position: relative;
    padding-left: 40px;
    border: none;

    padding-bottom: 5px;
    color: black;
    background-color: white;
}
.package-option:focus{

    outline: none;
}
.option-checkbox{   
  position: absolute;
  margin-left: 10px;
  left: 0;
  margin-top: 2px;
  height: 20px;
  width: 20px;
  background-color: var(--collaboration2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
