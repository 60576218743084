.package-selector-options{
    z-index: 2000;
    background-color: white;
    width: 350px;
    max-height: 250px;
    padding-bottom: 15px;
    padding-left: 60px;
     overflow-y: auto; 
     overflow-x: hidden;
  
}

/* Hide the browser's default checkbox */
.package-selector-options input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

.aggregation-container{
    max-height: 600px;
    width: 400px;
}

.aggregation-name{
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    font-size: medium;
    
}
.aggregation-name-field{
    padding-right: 10px;
}
.aggregate-item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 5px;
    font-size: medium;
    background-color: rgba(248, 249, 250, 1);
}
.aggregate-container{
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 25px;
    width: 500px;
}
.aggregate-edit-button{
    background-color: var(--collaboration1);
    color: white;  
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;   
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 35px;
    height: 35px;
    border: none;
}
.aggregate-edit-button:hover{
    background-color: var(--heritage);
    cursor: pointer;
}
.aggregate-name{
    width: 320px;
    white-space: nowrap;
    font-size: 18px;
}
.aggregate-item:hover{
    background-color: rgba(248, 249, 250, 1);
    cursor: pointer;
}
.aggregate-container .clarification-delete-button{
    width: 35px;
    height: 35px;
    border: none;
}
.general-upload-button:focus{

    outline: 1px;
  }