.package-selector-container{
    width: 200px;
    flex-direction: column;
 
}
.package-selector-dropdown{
    border: none;
    height: 30px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(248, 249, 250, 1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 200px;
    overflow-y: auto;
    overflow-x: hidden; 
}
.package-selector-dropdown:focus{

    outline: none;
}

input:focus{

  outline: 1px;
}
.package-selector-dropdown-options{
    position: absolute;
    background-color: white;
    width: 200px;
    z-index: 2000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 250px;
    padding-bottom: 5px;
     overflow-y: auto; 
     overflow-x: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.package-selector-dropdown-icon{
    padding-right: 10px;
}
.option-label{
  
    font-size: 16px;
}

/* Hide the browser's default checkbox */
.package-selector-dropdown-options input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .option-checkbox{   
    position: absolute;
    margin-left: 10px;
    left: 0;
    margin-top: 2px;
    height: 20px;
    width: 20px;
    background-color: var(--collaboration2);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


  /* On mouse-over, add a grey background color */
.package-option:hover input ~ .option-checkbox {
    background-color: var(--collaboration1);
  }
  
  /* When the checkbox is checked, add a blue background */
  .package-option input:checked ~ .option-checkbox {
    background-color: var(--heritage);
  }
  
  /* When the checkbox is checked, add a blue background */
  .package-option:hover input:checked ~ .option-checkbox {
    background-color: rgb(202, 82, 27);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .option-checkbox:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .package-option input:checked ~ .option-checkbox:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .package-option .option-checkbox:after {
    left: 8px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .package-option{
    width: 200px;
    text-align: left;
    display: block;
    position: relative;
    padding-left: 40px;
    border: none;

    padding-bottom: 5px;
    color: black;
    background-color: white;
}
.package-option:focus{

    outline: none;
}
.package-option-item{
  width: 200px;
  text-align: left;
  display: block;
  position: relative;
  padding-left: 10px;
  border: none;

  padding-bottom: 5px;
  color: black;
  background-color: white;
}
  .package-option-selected{
    width: 200px;
    text-align: left;
    display: block;
    position: relative;
    padding-left: 10px;
    border: none;
    padding-bottom: 5px;
    color: white;
    background-color: var(--heritage);
  }